<template>
    <wait-for-resource :resource="leagueGroups">
        <!--
            Only show league teams leaderboard if the league's group teams have
            been filled.
        -->
        <span v-if="league.status === LeagueStatus.WAITING_FOR_TEAMS">
            {{ $t('leagues.messages.league_not_started') }}
        </span>

        <!-- Hide tabs if there is only 1 group. -->
        <b-tabs
            v-else
            :nav-wrapper-class="[
                'text-center mb-4',
                {'d-none': leagueGroups.length === 1},
            ]"
            nav-class="nav-items-group d-inline-flex"
            pills
        >
            <b-tab
                v-for="(group, i) in leagueGroups.models"
                :key="`g-${i}`"
                :title="group.name"
                title-link-class="text-uppercase"
            >
                <b-table
                    :fields="fields"
                    :hover="canClickRow"
                    :items="group.league_group_teams.models"
                    responsive
                    @row-clicked="rowClicked"
                >
                    <template #cell(rank)="{value}">
                        <div class="bg-primary text-white btn-circle btn-sm">
                            {{ value }}
                        </div>
                    </template>

                    <template #cell(team.name)="{item}">
                        <ow-img
                            class="img-table rounded-circle mr-2"
                            :src="item.team.image"
                        />

                        <span class="font-weight-bold">
                            {{ item.team.name }}
                        </span>
                    </template>
                </b-table>
            </b-tab>
        </b-tabs>
    </wait-for-resource>
</template>

<script>
import Me from '@/library/Me';
import {League} from '@/models/League';
import Redirect from '@/library/Redirect';
import {BTabs, BTab} from 'bootstrap-vue';
import {LeagueGroups} from '@/models/LeagueGroup';
import LeagueStatus from '@/library/enums/LeagueStatus';

export default {
    name: 'LeagueLeaderboard',
    components: {BTabs, BTab},
    props: {
        league: {
            type: League,
            required: true,
        },
    },
    data: function() {
        return {
            /**
             * The LeagueGroups leaderboard of the given league.
             */
            leagueGroups: new LeagueGroups([], {
                routePrefix: this.league.getFetchURL() + '/leaderboards',
            }),
            LeagueStatus,
            me: new Me,
            // TODO: Ups, downs (wait for API).
            fields: [
                {
                    key: 'rank',
                    label: this.$t('common.words.rank'),
                    sortable: true,
                },
                {
                    key: 'team.name',
                    label: this.$t('team'),
                    class: 'w-auto',
                    sortable: true,
                },
                {
                    key: 'team_matches_score',
                    label: this.$t('common.words.game_points'),
                    sortable: true,
                },
                {
                    key: 'team_match_schedules_score',
                    label: this.$t('common.words.match_points'),
                    sortable: true,
                },
                {
                    key: 'team_matches_played_count',
                    label: this.$t('common.words.played'),
                    sortable: true,
                },
                {
                    key: 'team_matches_win_count',
                    label: this.$t('common.words.win'),
                    sortable: true,
                    tdClass: 'text-success',
                },
                {
                    key: 'team_matches_draw_count',
                    label: this.$t('common.words.draw'),
                    sortable: true,
                },
                {
                    key: 'team_matches_lose_count',
                    label: this.$t('common.words.lose'),
                    sortable: true,
                    tdClass: 'text-danger',
                },
            ],
        };
    },
    computed: {
        canClickRow() {
            return this.me.hasRole('admin')
                || _.get(
                    this.league,
                    'current_user_league_user.roles',
                    [],
                ).includes('admin');
        },
    },
    async created() {
        if (this.league.status !== LeagueStatus.WAITING_FOR_TEAMS) {
            await this.leagueGroups.fetch();

            this.leagueGroups.each(leagueGroup => {
                // Add and `rank` that will be used to display a rank. The
                // ranks are "shared", which means multiple teams can be on the
                // same rank.
                leagueGroup.league_group_teams.each((lgt, i) => {
                    const prev = i > 0
                        ? leagueGroup.league_group_teams.models[i - 1]
                        : undefined;

                    let rank = i + 1;

                    // Check if should share rank with previous.
                    if (prev && prev.team_matches_score == lgt.team_matches_score) {
                        rank = prev.rank;
                    }

                    this.$set(lgt, 'rank', rank);
                });
            });
        }
    },
    methods: {
        rowClicked(item) {
            if (this.canClickRow) {
                Redirect.to({
                    name: 'leagues.teams.show',
                    params: {
                        slug: this.league.slug,
                        teamSlug: item.team.slug,
                    },
                });
            }
        },
    },
};
</script>
